import React,{useState,useEffect, useMemo} from "react";
import axios from "axios";
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
	Container,
	Grid,
	Paper,
} from "@mui/material";
import {AtomViewSchoolsState} from "../../Recoil/AtomViewSchoolsState";
import ReactPaginate from 'react-paginate';
import './viewSchoolModal.css'


const ViewSchoolModal = ({isViewSchoolModalOpen,handleCloseViewSchooleModal}) => {
    const setViewSchoolsData = useSetRecoilState(AtomViewSchoolsState);
    const viewSchoolsData = useRecoilValue(AtomViewSchoolsState);
    const [schoolNameSearch, setSchoolNameSearch] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

     //----------Pagination------------     
     const [rowsPerPage, setRowsPerPage] = useState(3);
     const usersPerPage = rowsPerPage;
     const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
        setLoading(true)
        const response = await axios.get('https://worxit.co.za/api/schoolsZa/viewSchools.php');
        const newData = response.data.records;
        setViewSchoolsData(newData);
        setLoading(false)

      }; 
     
      fetchData();
      // const intervalId = setInterval(fetchData, 3000);
      // return () => clearInterval(intervalId);
    }, [setViewSchoolsData]);

    const filteredSchools = useMemo(() => {
        if (!Array.isArray(viewSchoolsData)) return [];
      
        return viewSchoolsData.filter((school) => {
          if (schoolNameSearch !== "") {
            const schoolName = school.schoolName.toLowerCase();
            const searchQuery = schoolNameSearch.toLowerCase();
            return schoolName.includes(searchQuery);
          }
          return true;
        });
      }, [viewSchoolsData, schoolNameSearch]);

      useEffect(() => {
        if (filteredSchools.length === 0 && schoolNameSearch !== "") {
          setMessage("No Match Found");
        } else {
          setMessage("");
        }
      }, [filteredSchools, schoolNameSearch]);
      
  return (
    
    <>
        {/* ---------View Schools Modal--------- */}
      {isViewSchoolModalOpen && (
        <div className="modalOverlay">
          <div className="modalContainerViewSchool">
            <div className="modalContentViewSchool">
            <div className="viewSchools" >
              <Container maxWidth="m" >
              <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="center"
              style={{ minHeight: "60vh", }}
            >
            <Paper elelvation={2} sx={{ padding: 5 }}>
            <form >
            <Grid container direction="column" spacing={2}>
              <h4 className="title">View Schools</h4>
              <Grid item>
              <>
        <div className="tableContainer" style={{width: "100%"}}>
            <div className=" row filter" >
                <div className="column1">
                    <h5>School Name:</h5>           
                </div>
                <div className="column2"> 
                    <input
                    className="searchInput"
                    type="text"
                    placeholder= "Search..."
                    onChange={(e) => setSchoolNameSearch(e.target.value)}/>  
                </div> 
                    
        </div>
            <table>
                <thead>
                    <tr>
                        <th className="orderNoWidth">School Type</th>
                        <th className="dateTimeWidth">School Name</th>
                        <th>School Address</th>
                        <th className="delAddNoWidth">School Site No </th>
                        <th>Pupil Enrolled in School</th>                  
                    </tr>
                </thead>
                <tbody>
                {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                    ) : (        
                                       
                      filteredSchools
                        .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                        .map((details, index) =>(
                            <tr key={index}>   
                                <td>{details.schoolType}</td> 
                                <td>{details.schoolName}</td>
                                <td>{details.schoolAddress}</td> 
                                <td>{details.siteSchoolNo}</td> 
                                <td>{details.schoolEnrolled}</td> 
                            </tr>
                            ))
            )}
                </tbody>
               
            </table>  
            {message && <p className="NoMatchFoundMsg">{message}</p>}

            <div className="rows-per-page">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(filteredSchools.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
           
        </div>
      </>
              </Grid>

              {/* <Grid item>
                  <Button type="submit" fullWidth variant="contained">
                Submit
              </Button>
              {message && <p className="message">{message}</p>} 
              {errorMessage && <p className="errorMsg">{errorMessage}</p>}
              </Grid> */}
            </Grid>
            </form>
            </Paper>
            </Grid>
            
            </Container>
                </div> 
                <div className="closeModalBtnContainer">
                  <button className="closeModalBtn" onClick={handleCloseViewSchooleModal}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
      )}

    </>
  );
};

export default ViewSchoolModal;