import React,{useState,useEffect, useMemo} from "react";
import axios from "axios";
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import { CSVLink } from "react-csv";
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
	Container,
	Grid,
	Paper,
} from "@mui/material";
import {AtomViewDeliveriesState} from "../../Recoil/AtomViewDeliveriesState";
import { AtomViewDeliveriesItemsState } from "../../Recoil/AtomViewDeliveriesItemsState";
import './orderDetailsModal.css'
import logo from '../../../media/RSA-Basic-Education-LOGO.jpg'

const OrderDetailsModal = ({ orderNo, handleCloseOrderDetailsModal }) => {
    const setviewDeliveriesData = useSetRecoilState(AtomViewDeliveriesState);
    const viewDeliveriesData = useRecoilValue(AtomViewDeliveriesState);
    const setViewDeliveriesItemsData = useSetRecoilState(AtomViewDeliveriesItemsState);
    const viewDeliveriesItemsData = useRecoilValue(AtomViewDeliveriesItemsState);
    const [pdfOpen, setPdfOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const orderDetails = useMemo(() => {
      if (!Array.isArray(viewDeliveriesData)) return null;
      return viewDeliveriesData.find((receive) => receive.orderNo === orderNo) || {};
    }, [viewDeliveriesData, orderNo]);
    
      useEffect(() => {
        const fetchDeliveriesData = async () => {
          const response = await axios.get(
            "https://worxit.co.za/api/schoolsZa/deliveriesView.php?orderNo=" +
            orderNo
          );
          const newData = response.data.records;
          setviewDeliveriesData(newData);
        };
    
        fetchDeliveriesData();
      }, [setviewDeliveriesData,orderNo]);

      const orderDetailsItems = useMemo(() => {
        if (!Array.isArray(viewDeliveriesItemsData)) return null;
    
        return viewDeliveriesItemsData.find((receive) => receive.orderNo === orderNo);
      }, [viewDeliveriesItemsData, orderNo]);
    
      useEffect(() => {
        const fetchDeliveriesItemsData = async () => {
          const response = await axios.get(
            "https://worxit.co.za/api/schoolsZa/deliveriesItemsTable.php?orderNo=" +
            orderNo
          );
          const newData = response.data.records;
          setViewDeliveriesItemsData(newData);
        };
    
        fetchDeliveriesItemsData();
      }, [setViewDeliveriesItemsData,orderNo]);

      //--------------EXPORT TO CSV-------------------
    
      const combinedTbleDataHeaders =[
        { label: 'Order No', key: 'orderNo' },
        { label: 'Date Time', key: 'dateTime' },
        { label: 'Receivers Name', key: 'receiversName' },
        { label: 'Site School No', key: 'siteSchoolNo' },
        { label: 'School Name', key: 'schoolName' },
        { label: 'Latitude', key: 'latitude' },
        { label: 'Longitude', key: 'longitude' },
        { label: 'Product Desc', key: 'productDesc' },
        { label: 'Unit of Measure', key: 'uom' },
        { label: 'Qty', key: 'qty' },
        { label: 'Expire', key: 'expire' },
      ];
  
      
      const combinedTbleData = useMemo(() => {
        if (!orderDetails || !orderDetailsItems) return [];
      
        return [
          {
            orderNo: orderDetails.orderNo,
            dateTime: orderDetails.dateTime,
            receiversName: orderDetails.receiversName,
            siteSchoolNo: orderDetails.siteSchoolNo,
            schoolName: orderDetails.schoolName,
            latitude: orderDetails.latitude,
            longitude: orderDetails.longitude
          },
          {
            productDesc: orderDetailsItems.productDesc,
            uom: orderDetailsItems.uom,
            qty: orderDetailsItems.qty,
            expire: orderDetailsItems.expire,
          },
        ];
      }, [orderDetails, orderDetailsItems]);

      //------------EXPORT TO EXCEL-------------

      const XLSX = require("xlsx");

      const handleExportToExcel = () => {
        const selectedColumns = orderDetails ? [
          {
            orderNo: orderDetails.orderNo,
            dateTime: orderDetails.dateTime,
            receiversName: orderDetails.receiversName,
            siteSchoolNo: orderDetails.siteSchoolNo,
            schoolName: orderDetails.schoolName,
            latitude: orderDetails.latitude,
            longitude: orderDetails.longitude
          }
        ] : [];
      
        const selectedColumns2 = orderDetailsItems ? [
          {
            productDesc: orderDetailsItems.productDesc,
            uom: orderDetailsItems.uom,
            qty: orderDetailsItems.qty,
            expire: orderDetailsItems.expire,
          }
        ] : [];
      
        const wb = XLSX.utils.book_new();
      
        const ws = XLSX.utils.json_to_sheet(selectedColumns, {
          header: [
            "orderNo",
            "dateTime",
            "receiversName",
            "siteSchoolNo",
            "schoolName",
            "latitude",
            "longitude"
          ],
        });
      
        const ws2 = XLSX.utils.json_to_sheet(selectedColumns2, {
          header: [
            "productDesc",
            "uom",
            "qty",
            "expire",
          ],
        });
      
        ws["!autofilter"] = { ref: "A1:E1" }; // Enable autofilter for the entire data range
        ws2["!autofilter"] = { ref: "A1:D1" }; // Enable autofilter for the entire data range
      
        XLSX.utils.book_append_sheet(wb, ws, "Dispatch");
        XLSX.utils.book_append_sheet(wb, ws2, "DispatchItems");
      
        XLSX.writeFile(wb, `Deliveries_${orderNo}.xlsx`);
      };

      //-------------EXPORT TO PDF-------------------

      const combinedTblPdfExport = async () => {
        if (!orderDetails || !Array.isArray(viewDeliveriesData)) return; // Check if orderDetails and viewDeliveriesData are available
    
        const pdf = new jsPDF();
    
          //------Image------
        pdf.addImage(logo, "PNG", 10, 3, 50, 20);
       
        //-----Report Date------
        const reportDate = new Date().toLocaleDateString();
        pdf.setFontSize(10);
          pdf.text(`Report Date: ${reportDate}`, 155, 10);
    
        //------Title------
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold');
        pdf.text(80, 20, 'Proof of Deliveries Report');
        pdf.setFont('helvetica', 'normal');
       pdf.text(15, 30, 'Deliveries Details');   
    
       const data = [
        [
          orderDetails.orderNo,
          orderDetails.dateTime,
          orderDetails.receiversName,
          orderDetails.schoolName,
          orderDetails.latitude,
          orderDetails.longitude,
          orderDetails.siteSchoolNo,
        ],
      ];
    
          const disSignature = new Image();
          //const guardsSignature = new Image();
          //const imageUriAll = new Image();
          disSignature.src = viewDeliveriesData.map((details) => [details.signRec])[0];
          //guardsSignature.src = viewDeliveriesData.map((details) => [details.signGuard])[0];
          //imageUriAll.src = showDispatchTbl.map((details) => [details.imageUriAll])[0];
              
        const tableData = [['Order No', ...data.map((row) => row[0])], 
        ['Date Time', ...data.map((row) => row[1])],
        ['receivers Name', ...data.map((row) => row[2])],
        ['Signature'],
        ['School Name', ...data.map((row) => row[3])],
        ['Latitude', ...data.map((row) => row[4])],
        ['Longitude', ...data.map((row) => row[5])],
        ['Site School No', ...data.map((row) => row[6])],
       
      ];
    
          pdf.autoTable({
            body: tableData,
            // theme: 'grid',
            startY: 35,
            alternateRowStyles: { 
                    fillColor: [208, 216, 223],
                  },
                  
            didParseCell: function (data) {
              // check if the current cell is the one to add an image to
              if (data.row.index === 3 && data.column.index === 1) {
                  // increase the height of the cell
                data.row.height = 25;
              }
            },
            didDrawCell: (data) => {

                // Add other image-related logic
                if (data.row.index === 3 && data.column.index === 1) {
                  // add the image to the cell
                  pdf.addImage(disSignature, 'PNG', data.cell.x + 5, data.cell.y - 8, 35, 35);
                }               
              }
                  
          });
             
      
    //----------------DISPATCH ITEMS TABLE------------------
    
    pdf.addPage()
      pdf.text(15, 10  , 'School Stock Details');
     
      const tableDataDel = [
        ['Product Desc', 'Units of Measure', 'Qty', 'Expire'], 
        ...viewDeliveriesItemsData.map(details => [details.productDesc, details.uom, details.qty, details.expire ]),
      ];
      
      const firstRow = viewDeliveriesItemsData[0];
    
      const headerRow = tableDataDel.shift();
    
    
      pdf.autoTable({
        head: [headerRow],
        body: [firstRow, ...tableDataDel],
        startY: 15,
        alternateRowStyles: {fillColor: [208, 216, 223]},
        // columnStyles: {
        //   0:{cellWidth:20},
        //   1:{cellWidth: 35},
        //   2: {cellWidth: 45}, 
        //   3: {cellWidth: 40},
        //   4: {cellWidth:45}
        // },
        // didParseCell: function (data) {
        //   if (data.row.index >= 1 && data.column.index === 4) {
        //     data.row.height = 50;
        //   }
        // },
        // didDrawCell: (data) => {
        //   if (data.column.index === 4 && data.row.index >= 1) {
        //     const details = viewDeliveriesItemsData[data.row.index - 1]; // subtract 1 to adjust for header row
        //     if (details && details.images) {
        //       const recSignature = new Image();
        //       recSignature.src = details.images;
        //       pdf.addImage(recSignature, 'PNG', data.cell.x -4, data.cell.y + 2, 46, 46);
        //     }
        //   }
        // },
           // Set margin to 20mm on all sides
        margin: {  bottom: 20 },
        }); 
        
        pdf.save('Full Report.pdf');
      }    
    
      
      return (
        <div className="modalOverlay">
          <div className="modalContainerViewSchool">
            <div className="modalContentViewSchool">
              <div className="viewDeliveries">
                <Container maxWidth="m">
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    justifyContent="center"
                    style={{ minHeight: "60vh" }}
                  >
                    <Paper elelvation={2} sx={{ padding: 5 }}>
                      <form>
                        <Grid container direction="column" spacing={2}>
                          <div className="titleContainer">
                            <h4 className="title">View Deliveries Details</h4>
                          </div>
                          <Grid item>
                            <>
                              <div
                                className="tableContainer"
                                style={{ width: "100%" }}
                              >
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Order No</th>
                                      <th>Date Time</th>
                                      <th>Receivers Name</th>
                                      <th>School Site</th>
                                      <th>School Name</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {loading ? (
                                      <tr>
                                        <td colSpan={11}>Loading...</td>
                                      </tr>
                                    ) : orderDetails ? (
                                      <tr>
                                        <td>{orderDetails.orderNo}</td>
                                        <td>{orderDetails.dateTime}</td>
                                        <td>{orderDetails.receiversName}</td>
                                        <td>{orderDetails.siteSchoolNo}</td>
                                        <td>{orderDetails.schoolName}</td>
                                      </tr>
                                    ) : null}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          </Grid>
                          
                        </Grid>
                      </form>
                      <div className="mediumMargin"></div>
                      <form>
                        <Grid container direction="column" spacing={2}>
                          <div className="titleContainer">
                            <h4 className="title">View Items Details</h4>
                          </div>
                          <Grid item>
                            <>
                              <div
                                className="tableContainer"
                                style={{ width: "100%" }}
                              >
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Order No</th>
                                      <th>siteSchoolNo</th>
                                      <th>productDesc</th>
                                      <th>uom</th>
                                      <th>qty</th>
                                      <th>expire</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {loading ? (
                                      <tr>
                                        <td colSpan={11}>Loading...</td>
                                      </tr>
                                    ) : orderDetailsItems ? (
                                      <tr>
                                        <td>{orderDetailsItems.orderNo}</td>
                                        <td>{orderDetailsItems.siteSchoolNo}</td>
                                        <td>{orderDetailsItems.productDesc}</td>
                                        <td>{orderDetailsItems.uom}</td>
                                        <td>{orderDetailsItems.qty}</td>
                                        <td>{orderDetailsItems.expire}</td>
                                      </tr>
                                    ) : null}
                                  </tbody>
                                </table>
                              </div>
                            
                            </>
                            
                          </Grid>
                          
                        </Grid>
                      </form>
                      <div className={"actionBtn"}>
                              <div >
                                <CSVLink className="exportCsvBtn"
                                data={combinedTbleData}
                                headers={combinedTbleDataHeaders}
                                filename={`combinedTbleData_${orderNo}.csv`}
                                target="_blank">
                                    Export to csv
                                </CSVLink>
                              </div>
                              <div>
                                <a className="exportExcelBtn" onClick={handleExportToExcel}>Export to Excel</a>
                                {/* <button className={'exportExcelBtn'} onClick={handleExportToExcel}>Export to Excel</button> */}
                              </div>
                              <div >
                                <a className="exportExcelBtn" onClick={combinedTblPdfExport}>Export to PDF</a>
                                  {/* {pdfOpen && (
                                    <Document
                                      file={file}
                                        onLoadSuccess={() => setPdfOpen(false)}
                                    >
                                    <Page pageNumber={1} />
                                    </Document>
                                    )} */}
                              </div>      
                            </div>
                    </Paper>
                    
                  </Grid>
                </Container>
              </div>
              <div className="closeModalBtnContainer">
                <button className="closeModalBtn" onClick={handleCloseOrderDetailsModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    };
export default OrderDetailsModal;
