import React, {useState, useEffect} from "react";
import "./navbar.css";
import logo from "../../media/RSA-Basic-Education-LOGO.jpg";
import Badge from '@mui/material/Badge';
import {  useNavigate } from 'react-router-dom';

const Navbar = ({ 
  handleLoadSchool, 
  handleViewSchool, 
  handleUploadMenu,
  handleDeliveries,
  handleCloseModal, 
  handleCloseViewSchooleModal,
  handleCloseUploadMenuModal,
  handleCloseDeliveries }) => {


  const [auth, setAuth]= useState();
  const navigate = useNavigate();


  useEffect(()=>{
  var auth = localStorage.getItem('username');
  setAuth(auth)
  },
  [])

  const LogOut= () =>{
    localStorage.removeItem('username');
    localStorage.clear();
    navigate('/');
    //window.location.reload();
  }

  const handleLoadSchoolClick = () => {
    handleLoadSchool();
    handleCloseViewSchooleModal();
    handleCloseUploadMenuModal();
    handleCloseDeliveries();
  };

  const handleViewSchoolClick = () => {
    handleViewSchool();
    handleCloseModal();
    handleCloseUploadMenuModal();
    handleCloseDeliveries();
  };

  const handleUploadMenuClick = () => {
    handleUploadMenu();
    handleCloseModal();
    handleCloseViewSchooleModal();
    handleCloseDeliveries();
  }

  const handleDeliveriesClick = () => {
    handleDeliveries();
    handleCloseModal();
    handleCloseViewSchooleModal();
    handleCloseUploadMenuModal();
  }

  return (
    <nav className="navbarContainer">
      <div className="leftSection">
        <img className="logo" src={logo} alt="logo" />
      </div>

      <div className="middleSection">
        <button className="menuBtn" onClick={handleLoadSchoolClick}>
          Load School
        </button>
        <button className="menuBtn" variant="outlined" onClick={handleUploadMenuClick}>
          Menu
        </button>
        <button className="menuBtn" variant="outlined" onClick={handleViewSchoolClick}>
          Schools
        </button>
        <button className="menuBtn" variant="outlined" onClick={handleDeliveriesClick}>
          Deliveries
        </button>
      </div>

      <div className="rightMiddleSection">
      <div className='fstColumn'>
          <div className='titleCol'>
            <h5>Non Compliance</h5>
          </div>
          <div className='subCol'>
            <text style={{paddingBottom: "5px"}}>Low</text>
            <text style={{paddingBottom: "5px"}}>Medium</text>
            <text style={{paddingBottom: "5px"}}>High </text>
          </div>
          <div className='badgeCol'>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={2} slotProps={{ badge: { className: 'low-badge' } }}></Badge>
            </div>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={10} slotProps={{ badge: { className: 'medium-badge' } }}></Badge>
            </div>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={5} slotProps={{ badge: { className: 'high-badge' } }}></Badge>
            </div>
          </div>
        </div>
        <div className='sndColumn'>
          <div className='titleCol'>
            <h5>Daily Order Status</h5>
          </div>
          <div className='subCol'>
            <text style={{paddingBottom: "5px"}}>Completed</text>
            <text style={{paddingBottom: "5px"}}>In Progress</text>
            <text style={{paddingBottom: "5px"}}>Not Started </text>
          </div>
          <div className='badgeCol'>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={4} color="success"></Badge>
            </div>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={3} color="primary"></Badge>
            </div>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={7} slotProps={{ badge: { className: 'high-badge' } }}></Badge>
            </div>
          </div>
        </div>
      </div>

      <div className="rightSection">
       
        <span className="title-bar-title">
          Welcome: {auth} 
          <button className="logoutBtn" onClick={LogOut}>logout </button> 
        </span>
      </div>
    </nav>
  );
};

export default Navbar;
