import React from "react";
import { RecoilRoot } from "recoil";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import App from "./App";
import Login from "./Pages/login/login";

const RootComponent = () => {
  return (
    <RecoilRoot>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/app" element={<App />} />
        </Routes>
      </Router>
    </RecoilRoot>
  );
};

export default RootComponent;
