import React,{useState,useEffect, useMemo} from "react";
import axios from "axios";
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
	Container,
	Grid,
	Paper,
} from "@mui/material";
import {AtomViewDeliveriesState} from "../../Recoil/AtomViewDeliveriesState";
import OrderDetailsModal from "../orderDetailsModal/orderDetailsModal";
import ReactPaginate from 'react-paginate';
import './viewDeliveriesModal.css'


const ViewDeliveriesModal = ({isDeliveriesModalOpen,handleCloseDeliveries}) => {
    const setviewDeliveriesData = useSetRecoilState(AtomViewDeliveriesState);
    const viewDeliveriesData = useRecoilValue(AtomViewDeliveriesState);
    const [orderNoSearch, setorderNoSearch] = useState("");
    const [dateTimeStart, setDateTimeStart] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [selectedOrderNo, setSelectedOrderNo] = useState(null);
    const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);


     //----------Pagination------------     
     const [rowsPerPage, setRowsPerPage] = useState(4);
     const usersPerPage = rowsPerPage;
     const [pageNumber, setPageNumber] = useState(0);

     useEffect(() => {
        const fetchDeliveriesData = async () => {
        const response = await axios.get('https://worxit.co.za/api/schoolsZa/deliveriesView.php');
        const newData = response.data.records;
        setviewDeliveriesData(newData);
      }; 
     
      fetchDeliveriesData();
      // const intervalId = setInterval(fetchData, 3000);
      // return () => clearInterval(intervalId);
    }, [setviewDeliveriesData]);
    
    
      const filteredReceive = useMemo(() => {
        if (!Array.isArray(viewDeliveriesData)) return [];
        
        return viewDeliveriesData.filter((receive) => {
          if (orderNoSearch !== "" || dateTimeStart !== "") {
            if (
              (receive.orderNo ?? "").includes(orderNoSearch) &&
              (receive.dateTimeRec ?? "").includes(dateTimeStart)
            ) {
              return receive;
            }
            return false;
          }
          return receive;
        });
        
      }, [viewDeliveriesData, orderNoSearch, dateTimeStart]);
    
            
        useEffect(() => {
          if (filteredReceive.length === 0 && (orderNoSearch !== "" || dateTimeStart !== "")) {
            setMessage("No Match Found");
          } else {
            setMessage("");
          }
        }, [filteredReceive, orderNoSearch, dateTimeStart]);
    
        const handleOrderClick = (e, orderNo) => {
          e.preventDefault();
          setSelectedOrderNo(orderNo);
          setIsOrderDetailsModalOpen(true);
        };

       
        const handleCloseOrderDetailsModal = () => {
          //isDeliveriesModalOpen(false);
          setIsOrderDetailsModalOpen(false);
        };
      
  return (
    
    <>
         {/* ---------View Deliveries Modal--------- */}
         {isDeliveriesModalOpen && (
        <div className="modalOverlay">
          <div className="modalContainerViewSchool">
            <div className="modalContentViewSchool">
            <div className="viewSchools" >
              <Container maxWidth="m" >
              <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="center"
              style={{ minHeight: "60vh", }}
            >
            <Paper elelvation={2} sx={{ padding: 5 }}>
            <form >
            <Grid container direction="column" spacing={2}>
              <h4 className="title">View Deliveries</h4>
              <Grid item>
              <>
              <div className="tableContainer" style={{width: "100%"}}>
        <div className=" row filter" >
            <div className="column1">
                <h5>Order Number:</h5>           
            </div>
            <div className="column2"> 
                <input
                className="searchInput"
                type="text"
                placeholder= "Search..."
                onChange={(e) => setorderNoSearch(e.target.value)}/>  
            </div>
            <div className="column3">
                <h5>Date:</h5>
            </div>
                <div className="column4">
                    <input
                        className="searchInput"
                        type="date"
                        placeholder= "Search..."
                        onChange={(e) => setDateTimeStart(e.target.value)}/>    
                </div>                  
    </div>
        <h6>Latest Deliveries...</h6>
        <table>
            <thead>
                <tr>
                  <th className="orderNoWidth">Order No</th>
                  <th>Date Time</th>
                  <th>Receivers Name</th>
                  <th>School Site</th>
                  <th>School Name</th>
                </tr>
            </thead>
            <tbody>
            {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                    ) : (        
                    filteredReceive
                        .sort((a, b) => new Date(b.dateTimeRec) - new Date(a.dateTimeRec))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage) 
                        .map((receive, index) =>(
                            <tr key={index}>   
                                <td>
                                <button className="orderNoBtn" onClick={(e) => handleOrderClick(e, receive.orderNo)}>
                                  {receive.orderNo}
                                </button>
                                </td>                                
                                <td>{receive.dateTime}</td>
                                <td>{receive.receiversName}</td>
                                <td>{receive.siteSchoolNo}</td>
                                <td>{receive.schoolName}</td>
                            </tr>
                            ))
            )}
                </tbody>
            </table> 
            <div className="rows-per-page">
             
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(filteredReceive.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>    
            {message && <p className="NoMatchFoundMsg">{message}</p>}
        </div>
      </>
              </Grid>

            </Grid>
            </form>
            </Paper>
            </Grid>
            
            </Container>
                </div> 
                <div className="closeModalBtnContainer">
                  <button className="closeModalBtn" onClick={handleCloseDeliveries}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          
      )}

{isOrderDetailsModalOpen && (
  <OrderDetailsModal orderNo={selectedOrderNo} handleCloseOrderDetailsModal={handleCloseOrderDetailsModal} />
)}

    </>
  );
};

export default ViewDeliveriesModal;