import React,{useState,useEffect} from "react";
import {
	Container,
	Button,
	Grid,
	Paper,
} from "@mui/material";
import './uploadMenuModal.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import axios from "axios";
import 'react-tabs/style/react-tabs.css';
import ReactPaginate from 'react-paginate';

const UploadMenuModal = ({isUploadMenuModalOpen,handleCloseUploadMenuModal}) => {
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [viewMenu, setViewMenu] = useState([])
    const [loading, setLoading] = useState(false);

    const [menuItems, setMenuItems] = useState({
        product: "",
        foodGroup: "",
        qty: ""
      })
        
      useEffect(() => {
        const fetchData = async () => {
        setLoading(true)
        const response = await axios.get('https://worxit.co.za/api/schoolsZa/viewMenu.php');
        const newData = response.data.records;
        setViewMenu(newData);
        setLoading(false)

      }; 
     
      fetchData();
      // const intervalId = setInterval(fetchData, 3000);
      // return () => clearInterval(intervalId);
    }, [setViewMenu]);

    //----------Pagination------------     
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const usersPerPage = rowsPerPage;
    const [pageNumber, setPageNumber] = useState(0);

     
      // const handleMenuChange = (e) => {
      //   if (e.target.name === "foodGroup") {
      //     setMenuItems({ ...data, foodGroup: e.target.value });
      //   } else {
      //     setMenuItems({ ...data, [e.target.name]: e.target.value });
      //   }
      // };
  
     
      // const handleMenuSubmit=(e)=>{
      //   e.preventDefault();
    
      //  const sendData={
      //   product: data.product,
      //   foodGroup:data.foodGroup,
      //   qty:data.qty,
        
      // }
      //   console.log(sendData)
      //   axios.post("https://worxit.co.za/api/schoolsZa/uploadMenu.php", sendData)
      //   .then((res)=> {
      //     setMessage(res.data.message);
      //   setErrorMessage("");
      //   setData({
      //     product: "",
      //     foodGroup: "",
      //     qty: "",
        
      //   });
      // })
      //   .catch((err) => {
      //     console.error(err);
      //     setErrorMessage("An error occurred.");
      //     setMessage("");
      //   });
        
      // }
   
      
  return (
    
    <>
          {/* ------------Upload Menu Modal------------ */}
      {isUploadMenuModalOpen && (
        <div className="modalOverlay">
          <div className="modalContainer">
            <div className="modalContent">
            <div className="loadSchools" >
              <Container maxWidth="sm" >
              <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="center"
              style={{ minHeight: "60vh", }}
            >
            <Paper elelvation={2} sx={{ padding: 5 }}>
            <form  >
            <Grid container direction="column" spacing={2}>
              
            <Tabs>
              <div className='trans'></div>
              <TabList className='tabsTitle'>
                <Tab>View Menu</Tab>
                <Tab>Upload Menu</Tab>
                
              </TabList>

              <TabPanel >
              <table>
                <thead>
                    <tr>
                        <th className="orderNoWidth">Food Group</th>
                        <th className="dateTimeWidth">Product Desc</th>
                        <th>Unit of Measure</th>
                        <th className="delAddNoWidth">Portion Primary</th>
                        <th>Portion Secondary</th>                  
                    </tr>
                </thead>
                <tbody>
                {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                    ) : (        
                                       
                      viewMenu
                        .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                        .map((details, index) =>(
                            <tr key={index}>   
                                <td>{details.foodGroup}</td> 
                                <td>{details.productDesc}</td>
                                <td>{details.uom}</td> 
                                <td>{details.portionPrim}</td> 
                                <td>{details.portionSec}</td> 
                            </tr>
                            ))
            )}
                </tbody>
               
            </table>  
            {message && <p className="NoMatchFoundMsg">{message}</p>}

            <div className="rows-per-page">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(viewMenu.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
              </TabPanel>

              <TabPanel>
              <h4 className="title">Upload Menu</h4>
              <Grid item>
              <select
                value={menuItems.foodGroup}
                name="schoolType"
                //onChange={handleMenuChange}
              >
                <option value="">Selct Food Group </option>
                <option value="breakfast">Breakfast</option>
                <option value="starch">Starch</option>
                <option value="protein">Protein</option>
              </select>

              </Grid>

              <Grid item>
              <input
                type= "text"
                name="product"
                placeholder="Product"
                variant="outlined"
                required
                value={menuItems.product}
                //onChange={handleMenuChange}
                
              />
              </Grid>

              <Grid item>
                <input
                  type= "text"
                  name="qty"
                  placeholder="QTY"
                  variant="outlined"
                  required
                  value={menuItems.qty}
                  //onChange={handleMenuChange}
                  
                />
              </Grid>

              <Grid item>
                  <Button type="submit" fullWidth variant="contained">
                Submit
              </Button>
              {message && <p className="message">{message}</p>} 
              {errorMessage && <p className="errorMsg">{errorMessage}</p>}
              </Grid>
              </TabPanel>

            

            </Tabs>
            
            </Grid>
            </form>
            </Paper>
            </Grid>
            
            </Container>
                </div> 
                <div className="closeModalBtnContainer">
                  <button className="closeModalBtn" onClick={handleCloseUploadMenuModal}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
      )}

    </>
  );
};

export default UploadMenuModal;