import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import L from 'leaflet';
import axios from 'axios';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import schoolIcon from "../../media/schoolIcon.png";
import locationMarker from "../../media/locationMarker.png";
import { AtomCoOrdinatesState } from '../../components/Recoil/AtomCoOrdinatesState';
import { AtomAddressState } from '../../components/Recoil/AtomAddressState';
import { useSetRecoilState } from 'recoil';



const MapWithSearch = ({ setCoordinates,setAddress, handleLoadSchool }) => {
  const map = useMap();
  const markerRef = useRef(null);
 
  useEffect(() => {
    // Create a custom marker icon
    const customLocationIcon = L.icon({
      iconUrl: locationMarker,
      iconSize: [20, 32], // Adjust the size of your custom marker
      iconAnchor: [10, 12], // Adjust the anchor point if needed
    });

    // Create the Control.Geocoder plugin with options
    const geocoderControl = L.Control.geocoder({
      defaultMarkGeocode: false, // Disable the default marker creation
    }).on('markgeocode', (event) => {
      const { center, name } = event.geocode;
      const { lat, lng } = center;

      // Remove any existing custom marker
      if (markerRef.current) {
        markerRef.current.remove();
      }

     // Create and add the custom marker at the geocoded location
      const marker = new L.Marker([lat, lng], {
        icon: customLocationIcon,
        draggable: true, // Enable dragging
      }).addTo(map);

      marker.on('dragend', async (event) => {
        const marker = event.target;
        const position = marker.getLatLng();
        const lat = position.lat.toFixed(6); // Round latitude to 6 decimal places
        const lng = position.lng.toFixed(6); // Round longitude to 6 decimal places
        setCoordinates([lat, lng]);
        setAddress(name)
        try {
          const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`;
          const response = await fetch(url);
          const data = await response.json();
          const address = data.display_name;
          setAddress(address)
          console.log('Address:', address);
      
          // Update popup content
          const popupContent = `<b>Address:</b> ${address}<br><b>Latitude:</b> ${lat}<br><b>Longitude:</b> ${lng}<br><br><button class="custom-button">Save</button>`;
          marker.getPopup().setContent(popupContent);
        } catch (error) {
          console.error('Reverse geocoding error:', error);
        }
      });


      markerRef.current = marker;

      const popupContent = `<b>Address:</b> ${name}<br><b>Latitude:</b> ${lat}<br><b>Longitude:</b> ${lng}<br><br><button class="custom-button">Save</button>`;
      const popup = L.popup().setContent(popupContent);

      // Open the popup when the marker is clicked
      marker.bindPopup(popup);

      // Handle button click event within the popup
      marker.on('popupopen', () => {
        const popupElement = marker.getPopup().getElement();

        // Find the button element inside the popup element
        const button = popupElement.querySelector('.custom-button');

        // Attach the event listener using Leaflet's DomEvent module
        L.DomEvent.on(button, 'click', () => {
          handleLoadSchool();
          console.log('Button clicked!');
        });
      });

      // Open the popup
      marker.openPopup();

      // Update the coordinates state
      setCoordinates([lat, lng]);
      setAddress(name)

      // Pan the map to the geocoded address location
      map.setView([lat, lng]);
    }).addTo(map);

    return () => {
      map.removeControl(geocoderControl);
    };
  }, [map, setCoordinates,setAddress,handleLoadSchool]);

  return null;
};

const Main = ({ handleLoadSchool }) => {
  
  const setCoordinates = useSetRecoilState(AtomCoOrdinatesState);
  const setAddress = useSetRecoilState(AtomAddressState);

  const [latLng, setLatLng] = useState([]);

  const coordinatesExist = latLng.length > 0;
  const defaultPosition = [-29.861825,31.009909];

  const position = coordinatesExist ? [latLng[0].lat, latLng[0].lon] : defaultPosition;

  const customIcon = L.icon({
    iconUrl: schoolIcon,
    iconSize: [45, 45],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://worxit.co.za/api/schoolsZa/viewAddress.php");
  
        console.log("API Response:", response.data);
  
        if (response.data && Array.isArray(response.data.records)) {
          const schoolCoordinates = response.data.records.map((school) => ({
            latitude: school.latitude,
            longitude: school.longitude,
            schoolAddress: school.schoolAddress,
          }));
        
          console.log("School Coordinates:", schoolCoordinates);
        
          const coordinatesPromises = schoolCoordinates.map(async (school) => {
            const coordinates = {
              lat: parseFloat(school.latitude),
              lon: parseFloat(school.longitude),
              schoolAddress: school.schoolAddress,
            };
            return coordinates;
          });
        
          const coordinates = await Promise.all(coordinatesPromises);
        
          setLatLng(coordinates);
        } else {
          console.error("Invalid API response format:", response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  

  return (
    <MapContainer center={position} zoom={10} style={{ height: '89vh', width: '100%' }} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {latLng.length > 0 && (
          <>
            {latLng.map((coordinate, index) => (
                <Marker position={[coordinate.lat, coordinate.lon]} icon={customIcon} key={index}>
                   <Popup>
                    <div>
                      <b>Address:</b> {coordinate.schoolAddress}<br />
                      <b>Latitude:</b> {coordinate.lat}<br />
                      <b>Longitude:</b> {coordinate.lon}<br />
                    </div>
                  </Popup>
                </Marker>
            ))}
          </>
        )}

      <MapWithSearch setCoordinates={setCoordinates} setAddress={setAddress} handleLoadSchool={handleLoadSchool}/>
    </MapContainer>
  );
};

export default Main;

