import React,{useState, useEffect,useMemo} from "react";
import './App.css';
import Navbar from './components/navbar/navbar';
import Main from './Pages/main/main';
import LoadSchoolModal from "./components/modals/loadSchoolModal/loadSchoolModal";
import UploadMenuModal from "./components/modals/uploadMenuModal/uploadMenuModal";
import ViewSchoolModal from "./components/modals/viewSchoolsModal/viewSchoolModal";
import ViewDeliveriesModal from "./components/modals/viewDeliveriesModal/viewDeliveriesModal";

const App = () => {
  const [isLoadSchoolModalOpen, setIsLoadSchoolModalOpen] = useState(false);
  const [isViewSchoolModalOpen, setIsViewSchoolModalOpen] = useState(false);
  const [isUploadMenuModalOpen, setIsUploadMenuModalOpen] = useState(false);
  const [isDeliveriesModalOpen, setIsDeliveriesModalOpen] = useState(false);

  const handleViewSchool = () => {
    setIsViewSchoolModalOpen(true)
  }

  const handleUploadMenu = () => {
    setIsUploadMenuModalOpen(true)
  }

  const handleDeliveries = () => {
    setIsDeliveriesModalOpen(true)
  }

  const handleLoadSchool = () => {
    setIsLoadSchoolModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsLoadSchoolModalOpen(false);
  };

  const handleCloseViewSchooleModal = () => {
    setIsViewSchoolModalOpen(false);
  };

  const handleCloseUploadMenuModal = () => {
    setIsUploadMenuModalOpen(false)
  }

  const handleCloseDeliveries = () => {
    setIsDeliveriesModalOpen(false)
  }
  
  return (

    <div className="container">
      <header>
        <Navbar 
        handleLoadSchool={handleLoadSchool} 
        handleViewSchool={handleViewSchool} 
        handleUploadMenu={handleUploadMenu}
        handleDeliveries={handleDeliveries}
        handleCloseModal={handleCloseModal}
        handleCloseViewSchooleModal={handleCloseViewSchooleModal}
        handleCloseUploadMenuModal ={handleCloseUploadMenuModal}
        handleCloseDeliveries={handleCloseDeliveries}
      />

      </header>
      <main className="mainBody">
        <Main handleLoadSchool={handleLoadSchool}/>

       
      </main>

         <LoadSchoolModal 
        isLoadSchoolModalOpen={isLoadSchoolModalOpen} 
        handleCloseModal={handleCloseModal}/>

        <UploadMenuModal
        isUploadMenuModalOpen={isUploadMenuModalOpen}
        handleCloseUploadMenuModal={handleCloseUploadMenuModal}
        />

        <ViewSchoolModal 
        isViewSchoolModalOpen={isViewSchoolModalOpen}
        handleCloseViewSchooleModal={handleCloseViewSchooleModal}
        />
       
       <ViewDeliveriesModal
       isDeliveriesModalOpen={isDeliveriesModalOpen}
       handleCloseDeliveries={handleCloseDeliveries}
       />
    </div>
      
  );
};

export default App;
