import React,{useState,useEffect} from "react";
import axios from "axios";
import { useRecoilValue } from "recoil"
import {
	Container,
	Button,
	Grid,
	Paper,
} from "@mui/material";
import { AtomAddressState } from "../../Recoil/AtomAddressState";
import { AtomCoOrdinatesState } from "../../Recoil/AtomCoOrdinatesState";
import './loadSchoolModal.css'


const LoadSchoolModal = ({isLoadSchoolModalOpen,handleCloseModal}) => {
    const addressState = useRecoilValue(AtomAddressState);
    const coordinates = useRecoilValue(AtomCoOrdinatesState);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [data, setData] = useState({
        schoolType: "",
        schoolName: "",
        schoolAddress: addressState || "",
        latitude: coordinates[0] || "",
        longitude: coordinates[1] || "",
        schoolSiteNo: "",
        schoolEnrolled: ""
      })
  
      useEffect(() => {
        setData((prevData) => ({
          ...prevData,
          schoolAddress: addressState || "",
          latitude: coordinates[0] || "",
          longitude: coordinates[1] || ""
        }));
         
      }, [addressState, coordinates]);

    const handleSchoolSubmit=(e)=>{
        e.preventDefault();
    
       const sendData={
        schoolType: data.schoolType,
        schoolName:data.schoolName,
        schoolAddress:data.schoolAddress,
        latitude:data.latitude,
        longitude:data.longitude,
        schoolSiteNo:data.schoolSiteNo,
        schoolEnrolled:data.schoolEnrolled,
      }
        console.log(sendData)
        axios.post("https://worxit.co.za/api/schoolsZa/loadSchools.php", sendData)
        .then((res)=> {
          setMessage(res.data.message);
        setErrorMessage("");
        setData({
          schoolType: "",
          schoolName: "",
          schoolAddress: "",
          latitude: "",
          longitude: "",
          schoolSiteNo: "",
          schoolEnrolled: "",
        });
        window.location.reload(); // Reload the page
      })
        .catch((err) => {
          console.error(err);
          setErrorMessage("An error occurred.");
          setMessage("");
        });
        
      }
      const handleChange = (e) => {
        if (e.target.name === "schoolType") {
          setData({ ...data, schoolType: e.target.value });
        } else {
          setData({ ...data, [e.target.name]: e.target.value });
        }
      };

   
      
  return (
    
    <>
        {/* ------------Load School Modal------------ */}
      {isLoadSchoolModalOpen && (
        <div className="modalOverlay">
          <div className="modalContainer">
            <div className="modalContent">
            <div className="loadSchools" >
              <Container maxWidth="sm" >
              <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="center"
              style={{ minHeight: "60vh", }}
            >
            <Paper elelvation={2} sx={{ padding: 5 }}>
            <form  onSubmit={handleSchoolSubmit}>
            <Grid container direction="column" spacing={2}>
              <h4 className="title">Load Schools</h4>
              <Grid item>
              <select
                value={data.schoolType}
                name="schoolType"
                onChange={handleChange}
              >
                <option value="">School Type </option>
                <option value="primary">Primary</option>
                <option value="secondary">Secondary</option>
              </select>

              </Grid>

              <Grid item>
              <input
                type= "text"
                name="schoolName"
                placeholder="School Name"
                variant="outlined"
                required
                value={data.schoolName}
                onChange={handleChange}
                
              />
              </Grid>

              <Grid item>
                <input
                  type= "text"
                  name="schoolAddress"
                  placeholder="School Address"
                  variant="outlined"
                  required
                  value={data.schoolAddress}
                  onChange={handleChange}
                  
                />
              </Grid>

              <Grid item>
              <input
                type="text"
                name="latitude"
                placeholder="Latitude"
                variant="outlined"
                required
                value={data.latitude}
                onChange={handleChange}
              />
              </Grid>

              <Grid item>
              <input
                type="text"
                name="longitude"
                placeholder="Longitude"
                variant="outlined"
                required
                value={data.longitude}
                onChange={handleChange}
              />
              </Grid>

              <Grid item>
                <input
                  type= "text"
                  name="schoolSiteNo"
                  placeholder="School Site No"
                  variant="outlined"
                  required
                  value={data.schoolSiteNo}
                  onChange={handleChange}
                  
                />
              </Grid>
              <Grid item>
                <input
                  type= "text"
                  name="schoolEnrolled"
                  placeholder="Pupil enrolled in current year"
                  variant="outlined"
                  required
                  value={data.schoolEnrolled}
                  onChange={handleChange}
                  
                />
              </Grid>
              <Grid item>
                  <Button type="submit" fullWidth variant="contained">
                Submit
              </Button>
              {message && <p className="message">{message}</p>} 
              {errorMessage && <p className="errorMsg">{errorMessage}</p>}
              </Grid>
            </Grid>
            </form>
            </Paper>
            </Grid>
            
            </Container>
                </div> 
                <div className="closeModalBtnContainer">
                  <button className="closeModalBtn" onClick={handleCloseModal}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
      )}
    </>
  );
};

export default LoadSchoolModal;