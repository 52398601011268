import {
	Container,
	Button,
	Grid,
	Paper,
	TextField,
	IconButton,
	InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./login.css";
// import logo from '../../media/ZTLogo.png';
// import largeLogo from '../../media/WorxitLogoLarge.png'

const Login = () => {

	const navigate = useNavigate();

	const [message, setMessage] = useState("");
	const [values, setValues] = useState({
		username: "",
		password: "",
	});

	const handleSubmit = (e) => {
		
		e.preventDefault();
		axios
			.post("https://worxit.co.za/api/schoolsZa/login.php", {
				username: values.username,
				password: values.password,
			})
			.then((res) => { 
				if (res.data.Status === '200') {
					window.localStorage.setItem('username', res.data.username);
					  navigate('/app');
					//   window.location.reload();
					
				  } else {
					setMessage('User Does Not Exist!');
				  }
				})
			.catch((err) => console.error(err));
	};
	const handlePassVisibilty = () => {
		setValues({
			...values,
			showPass: !values.showPass,
		});
	};

	return (
		
		<div className="login-form" >
			
			<Container maxWidth="sm">
				{/* <div className='largeLogo'>
					<span>
						<img src={largeLogo} alt="Logo" />
					</span>   				 
				</div>  */}
					<Grid
					container
					spacing={2}
					direction="column"
					justifyContent="center"
					style={{ minHeight: "60vh", }}
					>
						<Paper elelvation={2} sx={{ padding: 5 }}>
							<form  onSubmit={handleSubmit}>
								<Grid container direction="column" spacing={2}>
									<Grid item>
										<TextField
											type="text"
											fullWidth
											label="Enter your username"
											placeholder="Username"
											variant="outlined"
											required
											onChange={(e) => setValues({ ...values, username: e.target.value })}
										/>
									</Grid>
									<Grid item>
									<TextField
										type={values.showPass ? "text" : "password"}
										fullWidth
										label="Password"
										placeholder="Password"
										variant="outlined"
										required
										onChange={(e) => setValues({ ...values, password: e.target.value })}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={handlePassVisibilty}
														aria-label="toggle password"
														edge="end"
													>
														{values.showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									</Grid>
									<Grid item>
									<Button type="submit" fullWidth variant="contained">
										Sign In
									</Button>
									{message && <p className="errorMsg">{message}</p>}
									</Grid>
								</Grid>
							</form>
						
						</Paper>
						
					</Grid>
					{/* <div className="loginLogo">  
						<a href="https://zamatrack.co.za/" target={"_blank"} rel="noreferrer">					
							<span>
								<img className='loginLogoSize' src={logo} alt="Logo" />
							</span>
						</a>	
						<div >
							<label className="logoText">Powered by Zamatrack</label>
						</div>
					</div> */}
			</Container>
				
		</div>
	);
};

export default Login;